import React, { useState } from "react";

const AccordionItem = ({ title, text, id, checked }) => {
  const [isChecked, setChecked] = useState(!!checked);
  return (
    <div>
      <input
        onClick={() => setChecked(!isChecked)}
        className="ac-input"
        id={id}
        name="accordion-0"
        type="checkbox"
        checked={isChecked}
      />
      <label className="ac-label" htmlFor={id}>
        {title}
        <span />
      </label>
      <div className="article ac-content">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default AccordionItem;
