import React from "react";
import AccordionItem from "../components/accordion-item";
import Layout from "../components/layout";
import Seo from "../components/seo"

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="InfoSec Policy"
      />
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>729 InfoSec Policy</h2>
              <p>Effective Date: June 1, 2021</p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="accordion-729">
                <AccordionItem
                  id="0"
                  checked
                  title="How 729 Handles Information Security with Client Data"
                  text={[
                    <p>
                      729 Solutions is a custom software development shop that
                      also provides operational services and migration of
                      customer data from existing platforms to new platforms.
                    </p>,
                    <p>
                      Whenever possible in the development, testing, and staging
                      of software, the data used is not actual customer data,
                      but is contextual mocked data.
                    </p>,
                    <p>
                      However, there are instances where 729 Solutions has
                      access to live customer data. This includes when we are
                      migrating data for them, and when we are involved in
                      operations work for our clients.
                    </p>,
                    <p>
                      Information Security is an interconnected set of training,
                      policies, practices, and oversight, including:
                    </p>,
                    <ul className="text-primary">
                      <li>
                        <span className="text-dark">
                          An annual training session that presents the
                          importance of keeping customer data protected.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          A written policy that defines 729 Solutions intent to
                          proactively protect customer data that has been made
                          available to us.{" "}
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          An assertion by all 729 employees and contractors that
                          they have read the policy and understand the need to
                          protect customer data.{" "}
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          A written procedure that defines how we protect
                          customer data on our projects.{" "}
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          A checklist, implemented for each active project,
                          indicating the measures employed to keep customer data
                          secure within that project.{" "}
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          A periodic review of the Information Security
                          implementation checklist on all active projects, by a
                          Designated Security Officer.
                        </span>
                      </li>
                    </ul>,
                    <p>
                      The generally accepted standard for Information Security
                      is known as the C-I-A Triad:
                    </p>,
                    <ul className="text-primary">
                      <li>
                        <span className="text-dark">
                          Confidentiality - rules and practices that limit
                          access to information{" "}
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Integrity - rules and practices that assure that the
                          information is trustworthy and accurate
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Availability - rules and practices that ensure
                          information is available to authorized personnel when
                          needed.
                        </span>
                      </li>
                    </ul>,
                  ]}
                />
                <AccordionItem
                  id="1"
                  title="The Policy Statement"
                  text={[
                    <p>
                      729 Solutions takes the privacy and security of its
                      customer’s data very seriously.
                    </p>,
                    <ul className="text-primary">
                      <li>
                        <span className="text-dark">
                          Data is only accessible by the staff that is working
                          directly on an individual customer’s project.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          When possible, Development staff uses contextual
                          mocked data so that actual customer data is not part
                          of the development effort.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          When actual customer data must be used during
                          Development, the database where the data resides is
                          password protected. Passwords are encrypted and stored
                          in a password vault which is only accessible by
                          project participants.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Unless specifically required by a customer, all data
                          stored on Amazon Web Services products which are
                          behind industry-level security practices such as
                          Secure Socket Layer encryption during transit and
                          access control while at rest.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Code produced by 729 Solutions is stored in GitHub in
                          private repositories.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Communication of usernames, passwords, or other
                          security elements for a customer’s live data is passed
                          through a set of secure internal tools like{" "}
                          <a
                            href="https://1password.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            1Password
                          </a>
                          . These types of services also use Two-Factor
                          Authentication mechanisms so that the information is
                          accessible to trusted individuals and siloed on a per
                          project basis.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Each 729 Solutions employee and contractor is required
                          to read and report that they have read this Policy
                          Statement at least every 12 months.
                        </span>
                      </li>
                    </ul>,
                    <p>
                      A checklist of general practices that apply to all 729
                      Solutions projects is filled out by the Project Manager,
                      or designee, in concert with the project team to ensure
                      that due care is being taken with sensitive data. This
                      checklist is augmented with any additional practices that
                      apply to the specific project and client.
                    </p>,
                    <p>
                      Each checklist is reviewed by the company Designated
                      Security Officer to assure that it has been filled in and
                      practices appear to be complete.
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="2"
                  title="Specific Security Procedures"
                  text={[
                    <p>
                      As a software development and integrations consultant, 729
                      Solutions does not have its own enterprise infrastructure
                      nor products of its own but has occasion to to work with
                      clients that have specific information security concerns.
                      729 Solutions considers our security practices as the
                      baseline for all our clients and projects, however we
                      always look to enhance our policy with additional
                      requirements that an individual Client requires.
                    </p>,
                    <h4>Termination</h4>,
                    <p>
                      Upon resignation or termination, the Operations Manager
                      will ensure that all access to 729 and client resources
                      are revoked.
                    </p>,
                    <h4>Access to Production Data</h4>,
                    <p>
                      729 Solutions does not access a client’s production data
                      during development during support activities without
                      written authorization from the client. If such access is
                      required, only designated project personnel will be
                      granted access with this access provided by the client’s
                      security personnel. Client shall remove access as soon as
                      possible after the work is completed or upon notification
                      from 729.
                    </p>,
                    <h4>Managing Access Rights</h4>,
                    <p>
                      729 Solutions manages access to development resources
                      through our DevOps group at the direction of the 729
                      Project Manager. This access is only provided to the
                      necessary personnel. Access to the project development
                      infrastructure is removed upon project completion or an
                      individual is removed from the project.
                    </p>,
                    <p>
                      729 Solutions does not manage access to client production
                      data. This is the client’s responsibility.{" "}
                    </p>,
                    <h4>Unattended Laptops</h4>,
                    <p>
                      729 Solutions is a fully remote company. Since everyone’s
                      laptop is in their private residence there is limited
                      opportunity for any unauthorized person to gain access
                      using the device. However, laptops are required to lock
                      access after a maximum 15-minute period of inactivity.
                      Unlocking the laptop happens via password or biometric
                      authentication.
                    </p>,
                    <h4>Background Checks on Personnel</h4>,
                    <p>
                      A background check is performed when a contractor or
                      employee is onboarded to the company. Background checks
                      are redone once every three years.{" "}
                    </p>,
                    <h4>Use of Mobile Devices</h4>,
                    <p>
                      Personal mobile devices are permitted to access 729’s
                      email and instant messaging services. If using such a
                      device to test an implementation (e.g. website, mobile
                      application) only a staging version of the software (which
                      contains non-production data) may be accessed.
                    </p>,
                    <p>
                      Accessing production data through a personal mobile device
                      is only permitted with the written permission of the
                      client.
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="3"
                  title="Procedures to ensure Policy effectiveness"
                  text={[
                    <p>
                      These procedures are used to ensure that the spirit and
                      letter of the Policy is followed.
                    </p>,
                    <h4>Information Security Checklist</h4>,
                    <ol className="text-primary">
                      <li>
                        <span className="text-dark">
                          Review the customer’s original data store protection
                          mechanism (if data already exists) and identify
                          security concerns that they may want to consider
                          mitigating.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          If we take possession of their data (e.g. a csv
                          download, or a copy of their relational database) how
                          do we ensure that only the authorized personnel have
                          access to the data?
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Are all databases containing customer sensitive
                          information password protected?
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Do we only transmit their data over SSL?
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Do we (or does the cloud vendor) apply published
                          security updates to the operating systems, data
                          manipulation, and utility programs on a routine basis?
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          When the data is at rest, is it in an encrypted
                          storage mechanism?
                          <ul>
                            <li>
                              Describe the storage mechanism controls for each
                              data store (e.g. AWS, local developer copies)
                            </li>
                          </ul>
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Do Developers ever have real customer data on their
                          local computers?
                          <ol type="a" className="text-primary">
                            <li>
                              <span className="text-dark">
                                Is this necessary?
                              </span>
                            </li>
                            <li>
                              <span className="text-dark">
                                Have we considered appropriate workarounds?
                              </span>
                            </li>
                            <li>
                              <span className="text-dark">
                                Can we (and do we) sanitize actual customer data
                                that is used for development and testing
                                purposes? For example: using the ruby gem faker?{" "}
                              </span>
                              <ol type="i" className="text-primary">
                                <li>
                                  <span className="text-dark">
                                    UPDATE Customers SET SocSecNumber =
                                    Faker::Medical::SSN.ssn;
                                  </span>
                                </li>
                                <li>
                                  <span className="text-dark">
                                    UPDATE Customers SET CreditCardNumber =
                                    Faker::CreditCard.generate_number;
                                  </span>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          How do we ensure that local copies of the data are
                          secure and free from loss of integrity or
                          confidentiality?
                          <ol type="a" className="text-primary">
                            <li>
                              <span className="text-dark">
                                Local database copies are never uploaded to the
                                production database.
                              </span>
                            </li>
                          </ol>
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Software code is stored in a private GitHub cloud
                          repository where access is limited to 729 employees,
                          contractors, and the customer’s designated staff. All
                          updates placed in the repository are logged and
                          traceable to the individual that made the changes.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Have we documented the risks that are particular to
                          the specific work we are performing for the customer?
                          For example:
                        </span>
                        <ol type="a">
                          <li>
                            <span className="text-dark">
                              Live data for this project is stored in a local
                              relational database on the customer’s laptop.
                              <ol type="i" className="text-primary">
                                <li>
                                  <span className="text-dark">
                                    Database is protected by a username and
                                    password.
                                  </span>
                                </li>
                              </ol>
                            </span>
                          </li>
                        </ol>
                      </li>
                    </ol>,
                  ]}
                />
                <AccordionItem
                  id="4"
                  title="IT Security Information Breach Notification Policy"
                  text={[
                    <p>
                      An information technology (IT) security incident is an
                      event involving an IT resource at 729 Solutions that has
                      the potential of having an adverse effect on the
                      confidentiality, integrity, or availability of that
                      resource or connected resources. Resources include
                      individual computers, servers, storage devices and media,
                      and mobile devices, as well as the information, messages,
                      files, and/or data stored on them. Prompt detection and
                      appropriate handling of these security incidents is
                      necessary to protect 729 Solutions and our Clients
                      information assets and to preserve the privacy and
                      confidentiality of personal data.
                    </p>,
                    <p>
                      The purpose of this IT Security Information Breach
                      Notification Policy is to provide general guidance to 729
                      Solutions Team Members to to enable quick and efficient
                      recovery from security incidents; respond in a systematic
                      manner to incidents and carry out the steps necessary to
                      handle an incident; and minimize disruption to critical
                      computing services or loss or theft of sensitive or
                      mission critical information. In addition as 729 Solutions
                      works with client data this policy outlines the steps in
                      which we will take to notify our clients of any incident.
                    </p>,
                    <ol className="text-primary">
                      <li>
                        <span className="text-dark">
                          Identify a potential incident. The incident handler
                          may do so through monitoring of security sensors.
                          System owners or system administrators may do so by
                          observing suspicious system behavior. Any member of
                          the 729 Solutions team may identify (i.e., detect) a
                          potential security incident though external
                          complaint/notification, or other knowledge of
                          accidental or unlawful destruction, loss, alteration,
                          unauthorized disclosure of, or access to, High Risk
                          Data or GDPR Data.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Internal Notification: Members of the 729 Solutions
                          team that suspect an IT system or paper-based files
                          have been subject to accidental or unlawful
                          destruction, loss, or alteration, or unauthorized
                          disclosure or access, must immediately report the
                          situation to security@729solutions.com. Once the
                          incident handler is aware of a potential incident,
                          s/he will alert local system administrators.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Client Notification: 729 Solutions will immediately
                          (under 24 hours) notify clients of any potential
                          security incident. 729 Solutions team members will
                          work with the security team and follow all protocols
                          following the reporting of the incident.
                        </span>
                      </li>
                    </ol>,
                  ]}
                />
                <AccordionItem
                  id="5"
                  title="Partnership Methodologies"
                  text={[
                    <h4>
                      Basic Tenets of 729’s Partner Data Policy and Protection:
                    </h4>,
                    <ul className="text-primary">
                      <li>
                        <span className="text-dark">
                          No exporting of customer data from monitoring tools
                          without permission
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          No sharing screenshots of client dashboards without
                          permission
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          No displaying PII data in any tools
                        </span>
                      </li>
                    </ul>,
                    <h4>New Relic Certification Standards</h4>,
                    <p>
                      We always strive to comply with all applicable laws as
                      they take effect. This includes the European Union's
                      General Data Protection Regulation (GDPR) and all relevant
                      US State laws, such as the California Consumer Privacy Act
                      (CCPA).
                    </p>,
                    <p>
                      Our disk-based encryption provides additional security
                      while your data is at rest (FIPS 140-2 compliant ). In
                      addition, we are authorized for Moderate Impact SaaS
                      Services (FedRAMP Authorized Moderate) for accounts that
                      meet specific criteria.
                    </p>,
                    <h4>Zendesk Certification Standards</h4>,
                    <a
                      href="https://www.zendesk.com/company/privacy-and-data-protection/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.zendesk.com/company/privacy-and-data-protection/
                    </a>,
                    <h4>Recurly Certification Standards</h4>,
                    <a
                      href="https://recurly.com/product/security-and-compliance/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://recurly.com/product/security-and-compliance/
                    </a>,
                  ]}
                />
                <AccordionItem
                  id="6"
                  title="Periodic Assertion of Reading and Agreeing to this Information Security Policy"
                  text={[
                    <p>
                      Every year, each active 729 employee and contractor is
                      encouraged to read and assert their agreement to follow
                      this Policy via an email from their 729 Solutions email
                      address (or their business email address if they have not
                      been issued a 729 Solutions email address).
                    </p>,
                    <p>
                      The assertion can be in the words of the sender but this
                      is recommended content.
                    </p>,
                    <p>
                      “I have read the current version of the 729 InfoSec Policy
                      document and agree to follow the policy to the best of my
                      ability.“.
                    </p>,
                    <h4>Review of MFA settings and usage</h4>,
                    <p>
                      Storage mechanisms used for sensitive customer code and
                      data will enable Multi-Factor Authorization, if the tool
                      provides it.
                    </p>,
                    <p>These include:</p>,
                    <ul className="text-primary">
                      <li>
                        <span className="text-dark">
                          GitHub Private Repositories - The global feature
                          requiring MFA is enabled.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Amazon Web Services account logins - The global
                          feature requiring MFA is enabled.
                        </span>
                      </li>
                      <li>
                        <span className="text-dark">
                          Password Management - 1Password and our other Secret
                          Storage tools require MFA
                        </span>
                      </li>
                    </ul>,
                    <p>
                      Transitioning to MFA: Messages should be sent to all
                      GitHub and AWS account holders for 729, to have them
                      manually setup MFA.
                    </p>,
                    <h4>Amending this Policy</h4>,
                    <p>
                      This Information Security Policy shall only be amended
                      with the documented concurrence of both 729’s Chief
                      Executive Officer and the Designated Security Officer.
                    </p>,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
